import {ref, reactive} from 'vue';
import { defineStore } from 'pinia'
import { useApiCall } from '@/components/composables/api'; 

const initCompany = ()=>{
    return {
        company_id  :"",
        company_code  :"",
        display_company_name:"",
        
   }
}
const initEvent = ()=>{
    return {
        data_id  :"",
        event_code  :"",
        event_title  :"",
       
   }
}
export const useSmartToolStore = defineStore('smarttool', () => {
    const api = useApiCall()
    const selected_company = reactive(initCompany())
    const selected_data = reactive(initEvent())
    function setSelectedCompany (value) {
        selected_company.company_id = value.company_id
        selected_company.display_company_name = value.display_company_name
        selected_company.company_code = value.company_code
    }
    function setSelectedEvent (value) {
        selected_data.data_id = value.data_id
        selected_data.event_code = value.event_code
        selected_data.event_title = value.event_title
       
    }
    function clear(){
        Object.assign(selected_company,initCompany())
        Object.assign(selected_data,initEvent())
    }
    return { selected_company,selected_data,
        setSelectedCompany,setSelectedEvent,clear}
},{ persist: true})
