
import ManageLayout from '@/components/layouts/ManageLayout.vue';

export default [
    {
        path: '/manageGroup',
        component: ManageLayout,
        redirect:'/manageGroup/group/overview',
        props: true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"牧養管理",
            }
        },
        children: [
            
            {
                path: 'group/cluster',
                name: 'group/cluster',
                component: () => import('@/views/group/GroupClusterMainPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'group',
                    permissions:['group.#.edit'],
                    seo:{
                        title:"牧區設定",
                    }
                },
            },
            {
                path: 'group/list',
                name: 'group/list',
                component: () => import('@/views/group/GroupListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'group',
                    permissions:['group.#.edit'],
                    seo:{
                        title:"小組名單",
                    }
                },
            },
            
            {
                path: 'group/overview',
                name: 'group/overview',
                component: () => import('@/views/group/GroupOverviewPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'group',
                    permissions:['group.#.view'],
                    seo:{
                        title:"牧養總覽",
                    }
                },
            },
            
        ]
    },
   
];