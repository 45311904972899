import { ref,toRefs, reactive, computed,nextTick } from 'vue';
const __initDialogBox = () => {
    return {
        show: false,
        title:'訊息',
        message:"",
        loading_mode:false,
        icon:"",
        persistent:false,
        btnConfirmShow:true,
        btnConfirmText:"我知道了",
        btnConfirmEvent:null,
        btnCancelShow:false,
        btnCancelText:"取消",
        btnCancelEvent:null
       
    }
}
const layoutState = reactive({
    mainMenuActive: false,
});
const isAsyncDone = ref(true)
const showBlockFullScreen = ref(false)

const dialogBox = reactive(__initDialogBox());

export function useLayout() {
    const onMenuToggle = () => {
       console.log("onMenuToggle")
        layoutState.mainMenuActive = !layoutState.mainMenuActive;

    };
    const asyncDone = (value) => {
        isAsyncDone.value = value
    };
    const setBlockFullScreen = (value) => {
        if(value == false){
            setTimeout(()=>{        
                showBlockFullScreen.value = value
            },900)

        }else{
            showBlockFullScreen.value = value
        }
       
       
    };
  
    const openDialogBox = (options) => {
        Object.assign(dialogBox,__initDialogBox())
        Object.assign(dialogBox,options)
        dialogBox.show = true

    }
    const closeDialogBox = (options) => {
        dialogBox.show = false
    }
   
    return { 
        layoutState: toRefs(layoutState),
         dialogBox,isAsyncDone,showBlockFullScreen,

        openDialogBox,closeDialogBox,
        onMenuToggle,asyncDone,setBlockFullScreen
    };
}
