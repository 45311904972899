
import ManageLayout from '@/components/layouts/ManageLayout.vue';

export default [
    {
        path: '/manageGroup',
        component: ManageLayout,
        redirect:'/manageGroup/group/overview',
        props: true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"牧養管理",
            }
        },
        children: [
            {
                path: 'activity/list',
                name: 'activity/list',
                component: () => import('@/views/activity/ActivityListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    module:{
                        name:'activity',
                        column:''
                    },
                    seo:{
                        title:"點名表",
                    }
                },
            },
            {
                path: 'activity/setting/routine',
                name: 'activity/setting/routine',
                component: () => import('@/views/activity/ActivitySettingRoutinePage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    module:{
                        name:'activity',
                        column:''
                    },
                    seo:{
                        title:"定期聚會設定",
                    }
                },
            },
            {
                path: 'activity/setting/type',
                name: 'activity/setting/type',
                component: () => import('@/views/activity/ActivitySettingTypePage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    module:{
                        name:'activity',
                        column:''
                    },
                    seo:{
                        title:"聚會類別設定",
                    }
                },
            },
           
        ]
    },
   
];