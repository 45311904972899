
export default [
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/account/Register.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/account/Login.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/account/Logout.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/login_return',
        name: 'login_return',
        component: () => import('@/views/account/LoginReturn.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/verify',
        name: 'verify',
        component: () => import('@/views/account/VerifyPage.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
    {
        path: '/redeem-auth-link',
        name: 'redeem-auth-link',
        component: () => import('@/views/account/RedeemAuthPage.vue'),
        meta: { 
            requiresAuth: false ,
            seo:{
                title:"綁定授權"
            }
        },
    },
    {
        path: '/event-redeem-auth-link',
        name: 'event-redeem-auth-link',
        component: () => import('@/views/account/RedeemAuthPage.vue'),
        meta: { 
            requiresAuth: false ,
            seo:{
                title:"綁定授權"
            }
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/account/ForgotPassword.vue'),
        meta: { 
            requiresAuth: false ,
            seo:{
                title:"重設密碼"
            }
        },
    },
    {
        path: '/facebook/redirect',
        name: 'facebook/redirect',
        component: () => import('@/views/account/FacebookRedirect.vue'),
        meta: { 
            requiresAuth: false ,
        },
    },
];