
export default [
   
    {
        path: '/account/profile',
        name: 'account/profile',
        component: () => import('@/views/account/profile/ProfilePage.vue'),
        meta: { 
            requiresAuth: false ,
            seo:{
                title:"我的檔案",
            }
        },
    },
   
];