
export default [
            
    {
        path: '/payment_done',
        name: 'payment_done',
        component: () => import('@/views/payment/PaymentDone.vue'),
        props:true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"付款成功",
            }
        },
    },
    {
        path: '/payment_customerdone',
        name: 'payment_customerdone',
        component: () => import('@/views/payment/PaymentCustomerDone.vue'),
        props:true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"取號成功",
            }
        },
    },
    {
        path: '/payment_failed',
        name: 'payment_failed',
        component: () => import('@/views/payment/PaymentFailed.vue'),
        props:true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"付款失敗",
            }
        },
    },
    {
        path: '/order_canceled',
        name: 'order_canceled',
        component: () => import('@/views/payment/OrderCanceled.vue'),
        props:true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"付款失敗",
            }
        },
    },
     

    {
        path: '/pay',
        name: 'pay',
        component: () => import('@/views/payment/Pay.vue'),
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"付款",
            }
        },
    },
    {
        path: '/repay/:order_code',
        name: 'repay',
        component: () => import('@/views/payment/Repay.vue'),
        props:true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"重新付款",
            }
        },
    },
  
];