

export default [
    {
        path: '/smartTool',
        component: () => import('@/components/layouts/smartTool/SmartToolLayout.vue'),
        redirect:'/smartTool/events',
        props: true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"智慧工具",
            }
        },
        children: [
            {
                path: 'scan',
                name: 'smartTool/scan',
                component: () => import('@/views/smartTool/smartEvent/ScanTicket.vue'),
                meta: { 
                    requiresAuth: true ,
                    permissions:['event.#.edit','smartEvent.@.limitedit'],
                    seo:{
                        title:"驗票",
                    }
                },
            },
            {
                path: 'events',
                name: 'smartTool/events',
                component: () => import('@/views/smartTool/smartEvent/SmartEventListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    // function:'member',
                    // permissions:['member.#.view'],
                    seo:{
                        title:"我的活動列表",
                    }
                },
            },
            {
                path: 'ticketCheckList',
                name: 'smartTool/ticketCheckList',
                component: () => import('@/views/smartTool/smartEvent/SmartTicketCheckListPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.edit','smartEvent.@.limitedit'],
                     
                    seo:{
                        title:"智慧工具-驗票清單",
                    },
                    toolbar:{
                        title:'驗票清單',
                        showBack:true,
                        backTo:{name:'smartTool/scan'}
                    }
                },
            },
            {
                path: 'attendee',
                name: 'smartTool/attendee',
                component: () => import('@/views/smartTool/smartEvent/SmartEventAttendeePage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    permissions:['event.#.view','smartEvent.@.view'],
                     
                    seo:{
                        title:"活動報名訂單",
                    },
                    
                },
            },
           
           
        ]
    },
   
];