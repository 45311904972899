
import ManageLayout from '@/components/layouts/ManageLayout.vue';

export default [
    {
        path: '/manageMember',
        component: ManageLayout,
        redirect:'/manageMember/list',
        props: true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"會員中心",
            }
        },
        children: [
            
            {
                path: 'overview',
                name: 'member/overview',
                component: () => import('@/views/member/MemberOverviewPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'member',
                    permissions:['member.#.view'],
                    seo:{
                        title:"會員輪廓總覽",
                    }
                },
            },
            {
                path: 'list',
                name: 'member/list',
                component: () => import('@/views/member/MemberListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'member',
                    permissions:['member.#.edit'],
                    seo:{
                        title:"會員列表",
                    }
                },
            },
           
           
        ]
    },
   
];