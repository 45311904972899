import { ref,reactive} from 'vue';
import { defineStore } from 'pinia'

export const useNotifyStore = defineStore('notify', () => {
   
    const ad_dialog = ref({})
    const markAsRead =  (value) => {
       
        ad_dialog.value[value] = true
        console.log("markAsRead",ad_dialog.value,value,ad_dialog.value[value])
    }
   
    const hasRead =  (value) => {
        if(ad_dialog.value[value] === undefined){
            ad_dialog.value[value] = false
        }
        return ad_dialog.value[value]
    }
    return { ad_dialog,markAsRead,hasRead}
},{ persist: true})
