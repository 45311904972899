
import ManageLayout from '@/components/layouts/ManageLayout.vue';

export default [
    {
        path: '/manageEvent',
        component: ManageLayout,
        redirect:'/manageEvent/list',
        props: true,
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"活動管理",
            }
        },
        children: [
            {
                path: 'list',
                name: 'event/list',
                component: () => import('@/views/event/EventListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.view'],
                    seo:{
                        title:"活動列表",
                    }
                },
            },
            {
                path: 'event/add',
                name: 'event/add',
                component: () => import('@/views/event/EditEventPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.create'],
                    // module:{
                    //     name:'event',
                    //     column:''
                    // },
                    seo:{
                        title:"新增活動",
                    }
                },
            },
            {
                path: 'event/edit/:event_code',
                name: 'event/edit',
                component: () => import('@/views/event/EditEventPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.edit'],
                    module:{
                        name:'event',
                        column:'event_code'
                    },
                    seo:{
                        title:"編輯活動",
                    }
                },
            },
            {
                path: 'event/overview/:event_code',
                name: 'event/overview',
                component: () => import('@/views/event/EventOverviewPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.view'],
                    module:{
                        name:'event',
                        column:'event_code'
                    },
                    seo:{
                        title:"活動總覽",
                    }
                },
            },
            {
                path: 'event/attendee/:event_code',
                name: 'event/attendee',
                component: () => import('@/views/event/EventAttendeePage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.edit'],
                    module:{
                        name:'event',
                        column:'event_code'
                    },
                    seo:{
                        title:"活動參加名單",
                    }
                },
            },
            {
                path: 'event/notify/:event_code',
                name: 'event/notify',
                component: () => import('@/views/event/EventNotifyPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.edit'],
                    module:{
                        name:'event',
                        column:'event_code'
                    },
                    seo:{
                        title:"活動通知管理",
                    }
                },
            },
            {
                path: 'event/withdraw/:event_code',
                name: 'event/withdraw',
                component: () => import('@/views/event/EventWithdrawPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.edit'],
                    module:{
                        name:'event',
                        column:'event_code'
                    },
                    seo:{
                        title:"活動提領與帳務",
                    }
                },
            },
            {
                path: 'event/auth/:event_code',
                name: 'event/auth',
                component: () => import('@/views/event/EventAuthPage.vue'),
                props: true,
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.edit'],
                    module:{
                        name:'event',
                        column:'event_code'
                    },
                    seo:{
                        title:"活動工作人員管理",
                    }
                },
            },
            
            {
                path: 'organizer/list',
                name: 'organizer/list',
                component: () => import('@/views/organizer/OrgListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'event',
                    permissions:['event.#.view'],
                    // module:'event',
                    seo:{
                        title:"主辦單位",
                    }
                },
            },
        ]
    },
   
];