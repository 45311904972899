
import ManageLayout from '@/components/layouts/ManageLayout.vue';

export default [
    
    {
        path: '/manageCompany',
        component: ManageLayout,
        redirect:'/manageCompany/profile',
        meta: { 
            requiresAuth: true  ,
            seo:{
                title:"組織管理"
            }
        },
        children: [
            {
                path: 'profile',
                name: 'manageCompany/profile',
                component: () => import('@/views/company/CompanyProfilePage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'auth',
                    seo:{
                        title:"組織資料"
                    }
                },
            },
            {
                path: 'permission',
                name: 'manageCompany/permission',
                component: () => import('@/views/company/PermissionPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    function:'auth',
                    permissions:['auth.#.edit'],
                    seo:{
                        title:"角色與人員權限"
                    }
                },
                 
            },
            {
                path: 'apply/edit/:apply_id',
                name: 'manageCompany/apply/edit',
                component: () => import('@/views/company/ApplyEditPage.vue'),
                props:true,
                meta: { 
                    requiresAuth: true ,
                    ignoreCompany: true,
                    seo:{
                        title:"編輯組織申請"
                    }
                },
            },
            {
                path: 'myapplies',
                name: 'manageCompany/myapplies',
                component: () => import('@/views/company/CompanyApplyListPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    ignoreCompany: true,
                    seo:{
                        title:"組織申請列表"
                    }
                },
            },
            {
                path: 'addMerchantWebResult',
                name: 'addMerchantWebResult',
                component: () => import('@/views/company/AddMerchantWebResultPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    ignoreCompany: true,
                    seo:{
                        title:"組織申請結果"
                    }
                },
            },
        ]
    },
   
];