import axios from 'axios'
/*----Global AJAX Setting---------*/
axios.defaults.timeout= 600000;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.headers['CompanyId'] = '';
const $http=axios;
import { useUserStore } from '@/stores/user'
import { useCompanyStore } from '@/stores/company'
import { useSmartToolStore } from '@/stores/smarttool'
import {ref, computed} from 'vue';

// actions
const beforeApiCall=(method,api)=>{
  
  // consoleLog("is caling...",method,api);
}
const consoleLog=(text,json,data)=>{
  
   if(process.env.NODE_ENV=='development'||window.location.href.indexOf('test')>=0){
      console.log(text,json,data);
    }
}


const getHttpConfig=(utkn,company_id="",data_id="")=>{
  //自定義表頭只有在default有效
  axios.defaults.headers['ctk'] = company_id
  axios.defaults.headers['dtk'] = data_id
  axios.defaults.headers['source'] = process.env.VUE_APP_SOURCE
  return {
    headers: {
      authorization :`Bearer ${utkn}`,
      
    },
     
  };
}

export function useApiCall() {
    const store = useUserStore()
    const storeCompany = useCompanyStore()
    const storeSmartTool = useSmartToolStore()
    
    function postApi (api,json={}) {
        return new Promise((resolve, reject) => {
            beforeApiCall('post',api);
            let selected_company_id = store.checkSmartTool()?storeSmartTool.selected_company.company_id:storeCompany.selected_company.id
            $http.post(api,json,getHttpConfig(store.user.utkn,selected_company_id,storeSmartTool.selected_data.data_id))
            .then( ({data}) =>{
                    resolve(data);
                
            })
            .catch( (error)=> {
                let isNext = errorHandle(error.response)
                if(isNext != null){
                  resolve(isNext);
                }
            });
        })
    }
    function putApi (api,json={}) {
        return new Promise((resolve, reject) => {
            beforeApiCall('put',api);
            let selected_company_id = store.checkSmartTool()?storeSmartTool.selected_company.company_id:storeCompany.selected_company.id

            $http.put(api,json,getHttpConfig(store.user.utkn,selected_company_id,storeSmartTool.selected_data.data_id))
            .then( ({data}) =>{
                resolve(data);
            })
            .catch( (error)=> {
                let isNext = errorHandle(error.response)
                if(isNext != null){
                  resolve(isNext);
                }
            });
        })
    }
    function getApi (api,json={},other_conf = {}) {
        return new Promise((resolve, reject) => {
          let selected_company_id = store.checkSmartTool()?storeSmartTool.selected_company.company_id:storeCompany.selected_company.id

            let conf=getHttpConfig(store.user.utkn,selected_company_id,storeSmartTool.selected_data.data_id);
            conf.params = {...json};
            conf = Object.assign(conf, other_conf);
            beforeApiCall('get',api);
            $http.get(api,conf)
            .then( ({data}) =>{
              resolve(data)
            })
            .catch( (error)=> {
              let isNext = errorHandle(error.response)
              if(isNext != null){
                resolve(isNext);
              }
            });
        })
    }
    function deleteApi (api,json={}) {
        return new Promise((resolve, reject) => {
          let selected_company_id = store.checkSmartTool()?storeSmartTool.selected_company.company_id:storeCompany.selected_company.id

            let conf=getHttpConfig(store.user.utkn,selected_company_id,storeSmartTool.selected_data.data_id);
            conf.params = {...json};
            beforeApiCall('delete',api);
            $http.delete(api,conf)
            .then( ({data}) =>{
                resolve(data);
            })
            .catch( (error)=> {
              let isNext = errorHandle(error.response)
              if(isNext != null){
                resolve(isNext);
              }
            });
        })
    }
    const errorHandle = (error)=>{

        consoleLog("error",error);
        error.status = parseInt(error.status);
        if(error.status==401){
          if(error.data.code == 6){
            consoleLog("verify needed",store.router.currentRoute.value.fullPath);
            if(store.router.currentRoute.value.fullPath.indexOf('/verify')>=0){
              store.router.go(0);

            }else{
              store.router.push({name:'verify'});
            }
            return null
          }else{
            store.router.push({name:'login'});
            console.log("logout")
            return null
          }
      }else if(error.status==429){ 
        alert("請求次數過多，請稍後再試。")
      }
      //   else if(error.status==403){
      //     commit('dialogBox',{dialog:true,option:{title:"權限不足",message:"你沒有權限瀏覽此內容喔！"}},{root:true});
      //   } 
      //   else if(error.status==406||(error.status==400&&error.data.code == 2)){
      //     commit('dialogBox',{dialog:true,option:{title:"參數錯誤",message:"Oops!你的資料可能有錯誤！請檢查後再試一次"}},{root:true});
      //   }else{ 
      //     commit('dialogBox',{dialog:true,option:{title:`伺服器錯誤${error.status}`,message:"Oops!伺服器發生錯誤！請聯繫系統管理員或稍後再試"}},{root:true});
      //   }
        return error.data;
      };
    return{ 
        getApi,postApi,putApi,deleteApi
    }
}