import { reactive,ref,computed} from 'vue';
import { defineStore } from 'pinia'
import { useApiCall } from '@/components/composables/api'; 
import { useCompanyStore } from '@/stores/company'
import { useSmartToolStore } from '@/stores/smarttool'

const initState = ()=>{
    return {
        uid  :"",
        utkn  :"",
        name  :"",
        avatar  :"",
        is_verified:false,
        permissions:[],
        is_superadmin:false,
        user_code:""
   }
}

export const useUserStore = defineStore('user', () => {
    const storeCompany = useCompanyStore()
    const storeSmartTool = useSmartToolStore()

    const api = useApiCall()
    const user = reactive(initState())
    const whereiwas = reactive({
        fullpath:"",
        query:{},
        params:{}
    })
   
    const setWhereiwas =  (value) => {
        whereiwas.fullpath = value.fullpath
        whereiwas.query = value.query
        whereiwas.params = value.params
    }
    const setUserToekn =  (value) => {
        user.utkn = value
    }
    const setUserProfile =  (value) => {
        user.uid = value.id
        user.user_code = value.user_code
        user.name = value.name.length>0?value.name : value.nickname
        user.avatar = value.avatar
        user.is_verified = value.is_verified
        user.permissions = value.permissions === undefined? []: value.permissions
        user.is_superadmin = value.is_superadmin
     }
    
    const getMyProfile = async () => {
        try {
            let data =  await api.getApi("me/profile/simple") 
            if(data.status){
                setUserProfile(data.user)
                return true
            }else{
                return false
            }
        
        } catch (error) {
            return error
        }
    }
    function canAction(permit_list){
        let isSmartTool =checkSmartTool()
        for(let i =0 ; i< permit_list.length;i++){
            let permit = permit_list[i]
            let company_id =  isSmartTool?storeSmartTool.selected_company.company_id:storeCompany.selected_company.id
            let data_id = storeSmartTool.selected_data.data_id
            let permit_name = permit.replace('#',company_id).replace('@',data_id)
           
            if(user.permissions.indexOf(permit_name)>=0){
                return true
            }
        }
        return false
        
    }
    function canInSmartTool(permit){
        if(!permit && checkSmartTool()) return false
        return true
    }
    function checkSmartTool(){
        return  window.location.href.indexOf('/smartTool')>=0
    }
    const logout =  () => {
        Object.assign(user,initState())
        storeCompany.clear()
        storeSmartTool.clear()
    }
    return { user,whereiwas,checkSmartTool,
        getMyProfile,
        setUserToekn,
        setUserProfile,
        setWhereiwas,
        logout,canAction,canInSmartTool}
},{ persist: true})
