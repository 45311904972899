import ManageLayout from '@/components/layouts/ManageLayout.vue';

export default [
    {
        path: '/admin',
        component: ManageLayout,
        redirect:'/admin/applications',
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"超級管理者後台",
            }
        },
        children: [
            {
                path: 'dashboard',
                name: 'admin/dashboard',
                component: () => import('@/views/admin/AdminDashboardPage.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"統計數據",
                    }
                },
            },
            {
                path: 'applications',
                name: 'admin/applications',
                component: () => import('@/views/admin/ManageApplyPage.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"組織申請列表",
                    }
                },
            },
            
            {
                path: 'withdraw',
                name: 'admin/withdraw',
                component: () => import('@/views/admin/ManageWithdrawPage.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                },
            },
            {
                path: 'authcenter',
                name: 'admin/authcenter',
                component: () => import('@/views/admin/ManageAuthCenterPage.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"組織權限中心",
                    }
                },
            },
            {
                path: 'order',
                name: 'admin/order',
                component: () => import('@/views/admin/ManageOrderPage.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"訂單查詢",
                    }
                },
            },

            {
                path: 'function',
                name: 'admin/function',
                component: () => import('@/views/admin/ManageFunctionPage.vue'),

                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"模組設定",
                    }
                },
            },
            
            {
                path: 'command',
                name: 'admin/command',
                component: () => import('@/views/admin/ManageCommandPage.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"批次執行",
                    }
                },
            },
            {
                path: 'jobmonitor',
                name: 'admin/jobmonitor',
                component: () => import('@/views/admin/JobMonitorPage.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"排程監控",
                    }
                },
            },
            {
                path: 'merchant',
                name: 'admin/merchant',
                component: () => import('@/views/admin/MerchantPage.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"藍新合作商店",
                    }
                },
            },
            {
                path: 'apitest',
                name: 'admin/apitest',
                component: () => import('@/views/admin/APITest.vue'),
                meta: { 
                    permissions:['super.edit'],
                    requiresAuth: true ,
                    seo:{
                        title:"API模擬",
                    }
                },
            },
        ]
    },
   
];